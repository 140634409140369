.GuidesList {
  display: flex;
  flex-direction: column;
}

/* .GuidesList > span {
  display: flex;
} */

.GuideItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-bottom: 16px;
  min-width: 100%;
}

.GuideItem > span:hover {
  color: #D10A0A;
}

