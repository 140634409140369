.Footer {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
  margin-top: 18px;
  padding: 8px 4.2% 13px 4.2%;
  background-color: #FBE4D5;
}

.SiteLegal {
  display: flex;
}

@media screen and (min-width: 425.5px) {
  .Footer{
    flex-direction: column-reverse;
    align-items: center;
  }
}

@media screen and (min-width: 768.5px) {
  .Footer {
    flex-direction: row;
  }
}
