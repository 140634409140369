.SiteMap {
  font-family: 'Rokkitt', serif;
  font-size: 24px;
  margin-right: 40px;
}

.SiteMapLinks {
  display: flex;
  flex-direction: column;
  font-size: 18px;
}
