.Button {
  display: flex;
  width: fit-content;
  font-family: 'Rokkitt';
  font-size: 1.125rem;
  font-weight: 500;
  color: #C6F3F3;
  background-color: #AF0606;
  padding: 8px;
  border: 3px solid #C6F3F3;
  border-radius: 8px;
  background-clip: padding-box;
}

.Button:hover {
  background-color: #D10A0A;
  cursor: pointer;
}
