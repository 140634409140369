button {
  /* display: flex;
  width: fit-content; */
  font-family: 'Rokkitt';
  font-size: 1.125rem;
  /* font-weight: 500; */
  color: #C6F3F3;
  background-color: #AF0606;
  padding: 8px;
  border: 3px solid #C6F3F3;
  border-radius: 8px;
  background-clip: padding-box;
}

button:hover {
  background-color: #D10A0A;
  /* cursor: pointer; */
}

.Wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
}

.ContactForm, form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  height: 473px;
  background: #FBE4D5;
  border-radius: 8px;
  z-index: 100;
}

form {
  width: 100%;
  padding: 16px;
}

.BackDrop {
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background: rgba(0, 0, 0, 0.55);
  z-index: 99;
}

form .FormTitle {
  font-family: Rokkitt;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
  width: 100%;
  height: 35px;
  margin: 8px 0px;
}

form .FormTitle>span {
  font-family: Rokkitt;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
}

.FormTitle>img {
  cursor: pointer;
}

.DataUnit {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  margin-bottom: 10px;
}

.DataUnit span {
  margin-left: 4px;
  font-size: 12px;
}

.DataUnit input, textarea {
  display: flex;
  align-self: stretch;
  font-size: 14px;
  background: #FFFFFF;
  border: 4px solid rgba(198, 243, 243, 0.35);
  border-radius: 4px;
  padding: 4px 10px;
  margin: 0px;
}
