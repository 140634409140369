.LogoComp {
  display: flex;
  flex-direction: column;
}

.LogoName{
  display: flex;
  font-size: 1.5rem;
  align-items: center;
}

span{
  font-family: 'Rokkitt', serif;
  font-weight: 400;
}

img {
  margin-right: 0.75rem;
}
