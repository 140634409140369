
.Home {
  padding: 8px 4.2% 0 4.2%;
}

.Hero_Img {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  /* height: 95%; */
  height: 480px;
  background: linear-gradient(180deg, #FBE4D5 0%, #FBE4D5 13.54%, rgba(251, 228, 213, 0) 100%), url(../../images/hero_img.jpg);
  background-size: cover;
  background-position: center;
  z-index: -1;
  border-bottom: 8px solid #FBE4D5;
}

.Hero_Area {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Txt_1 {
  font-family: 'Rokkitt', serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 73px; */
  text-align: center;
  margin-top: 43px;
}

.Txt_2 {
  font-family: 'Rokkitt', serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 20px; */
  text-align: center;
}

.Txt_3 {
  font-family: 'Rokkitt', serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 20px; */
  text-align: center;
  margin-top: 95px;
}

.Txt_4 {
  display: flex;
  justify-content: center;
  font-family: 'Rokkitt', serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 23px; */
  text-align: center;
  margin-top: 144px;
}

@media screen and (min-width: 425.5px) {
  .Txt_1 {
    font-size: 44px;
  }

  .Txt_3 {
    font-size: 28px;
    /* line-height: 32px; */
  }

  .Txt_4 {
    font-size: 28px;
    /* line-height: 32px; */
    margin-top: 100px;
  }
}

@media screen and (min-width: 768.5px) {
  .Txt_1 {
    font-size: 60px;
  }

  .Txt_3 {
    font-size: 36px;
    /* line-height: 41px; */
  }

  .Txt_4 {
    font-size: 36px;
    /* line-height: 41px; */
    margin-top: 270px;
  }

  .Hero_Img {
    height: 660px;
  }
}
