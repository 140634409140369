.Card {
  display: flex;
  flex: 1;
  margin-right: 6px;
  margin-bottom: 8px;
  flex-direction: column;
  padding: 8px;
  align-items: center;
  min-width: 180px;
  max-width: 180px;
  /* flex-basis: 190px; */
  font-family: Rokkitt;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
  border: 1px solid #AF0606;
  box-sizing: border-box;
  border-radius: 10px;
}
