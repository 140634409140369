.Btns {
  display: flex;
  flex-direction: column;
  align-items: inherit;
  margin-bottom: 32px;
}

.Btns span {
  display: flex;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
}

.Txt {
  visibility: hidden;
  display: none;
}

@media screen and (min-width: 425.5px) {
  .Txt {
    visibility: hidden;
    display: none;
  }
}

@media screen and (min-width: 768.5px) {
  .Btns {
    align-items: inherit;
  }

  .Txt {
    display: flex;
    visibility: visible;
  }
}
