.Header{
  display: flex;
  flex-direction: column;
  background-color: #FBE4D5;
  padding: 0.5rem 4.2% 0 4.2%;
}

.HeaderTop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

}

.NavAccArea {
  display: flex;
  justify-content: flex-end;
}

.Caption{
  font-size: 12px;
}

@media screen and (min-width: 425.5px) {
  .Caption{
    font-size: 16px;
  }
}

@media screen and (min-width: 768.5px) {
  .Caption{
    font-size: 18px;
  }
}
