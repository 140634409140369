.Wrapper {
  display: flex;
  align-self: center;
  width: 100%;
  height: 100%;
}

.Admin {
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 90%;
  padding-top: 16px;
}

.Centralize {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
}

.AdminSidebar{
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  min-width: max-content;
  padding: 12px 32px 12px 32px;
}

.AdminSidebar > div {
  margin-bottom: 10px;
}

.AdminContent{
  display: flex;
  width: 80%;
  padding: 20px 40px;
}

.AdminContent > input, .AdminContent > button {
  margin-bottom: 20px;
}

.Alert {
  position: static;
  left: 0px;
  top: 0px;
  right: 0px;
  font-family: Lato, Arial, Helvetica, sans-serif;
  font-size: 12px;
  background-color: #AF0606;
  color: white;
  text-align: center;
  transition: opacity 0.5s;
  opacity: 1;
  padding: 4px 0px;
  z-index: -1;
}


@media screen and (min-width: 425.5px) {
  
}

@media screen and (min-width: 768.5px) {
  .Admin {
    flex-direction: row;
    width: 75%;
    padding-top: 32px;
  }

  .AdminSidebar{
    flex-direction: column;
    justify-content: flex-start;
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom: none;
    padding: 12px 32px 12px 12px;
  }
}