.NavMenu{
  font-family: 'Rokkitt', serif;
  font-size: 1.125rem;
  font-weight: 400;
  margin-right: 1.875rem;
}

.NavMenu:hover {
  color: #D10A0A;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: black;
}

a:visited{
  color: black;
}
