.Nav{
  display: flex;
  justify-content: space-between;
}

.Nav a {
  text-decoration: none;
}

.Menus {
  display: none;  
}


@media screen and (min-width: 768.5px) {
  
  .Menus {
    display: block;  
  }
  
  .Hamburger {
    display: none;
  }
  
}
