.Legal {
  font-family: 'Rokkitt', serif;
  font-size: 24px;
}

.LegalLinks {
  display: flex;
  flex-direction: column;
  font-size: 18px;
}
