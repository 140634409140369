.Common {
  display: flex;
  flex-direction: column;
  font-family: 'Rokkitt', serif;
  margin-top: 3rem;
  padding: 0 4.2% 0 4.2%;
}

.ContentNav {
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 32px;
}

.ContentNavMenus {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.NavMenu {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  padding-top: 1rem;
  margin-left: 12px;
  margin-right: 12px;
  text-align: center;
  flex: 1;
}

.NavMenu:hover {
  cursor: pointer;
  color: #D10A0A;
}

.CenterBtns {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 6rem;
  margin-top: 3.5rem;
}

/* Only show Section Title on wide screens */
.WideOnly {
  display: none;
}

/* Only show Side Buttons on wide screens */
.ContentNavBtns {
  display: none;
}

/* Only show Center Buttons on small screens */
.CenterBtns span {
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
}

span+div {
  margin-bottom: 14px;
}

.Center {
  font-family: 'Lato' sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
}

.Center h4 {
  display: inline-block;
  color: white;
  padding: 4px 10px;
  background-color: #D10A0A;
  margin-bottom: 0px;
  border-radius: 4px;
}

.Center h5{
  margin-bottom: 4px;
  padding-bottom: 0px;
  border-bottom: 0px;
}

.Center p {
  margin-block-start: 8px;
}

.GuideList li:hover {
  cursor: pointer;
  color: #D10A0A;
}

.Ads_1, .Ads_2 {
  display: flex;
  width: 100%;
  /* min-height: 100px; */
  /* background-color: cornflowerblue; */
}


.Alert {
  position: absolute;
  left: 0px;
  /* top: auto; */
  right: 0px;
  font-family: Lato, Arial, Helvetica, sans-serif;
  font-size: 12px;
  color: white;
  text-align: center;
  transition: opacity 0.5s;
  opacity: 1;
  padding: 4px 0px;
}

@media screen and (min-width: 425.5px) {
  .ContentNavBtns {
    display: none;
  }

  .CenterBtns {
    display: flex;
    align-items: center;
    text-align: center;
  }

  .NavMenu {
    font-size: 24px;
  }

  .Ads_1, .Ads_2 {
    display: flex;
    /* min-height: 100px; */
    justify-content: center;
    align-items: center;
  }
}

@media screen and (min-width: 768.5px) {
  .Common {
    flex-direction: row;
  }

  .ContentNav {
    flex-direction: column;
    justify-content: space-between;
    min-width: 275px;
    max-width: 275px;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    text-align: left;
    margin-right: 32px;
    min-height: 370px;
  }

  .ContentNavMenus {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
  }

  .ContentNavBtns {
    display: flex;
    flex-direction: column;
  }

  .ContentNavBtns span {
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    text-align: left;
  }
  
  .NavMenu {
    text-align: left;
  }

  .Center {
    padding-right: 16px;
  }

  .CenterBtns {
    display: none;
  }
  
  .WideOnly {
    display: flex;
  }

  .Ads_1 {
    display: none;
  }

  .Ads_2 {
    display: flex;
    /* min-width: 160px; */
    max-width: 200px;
    height: auto;
    /* background-color: cornflowerblue; */
    margin-left: auto;
  }
}
