.x404 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.x404 p {
  font-family: Rokkitt;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 41px;
  letter-spacing: 0em;
  text-align: center;
}

.x404 span {
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  text-align: center;
}

.x404 .Btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 300px;
}