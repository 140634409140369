.DropMenuItem {
  display: flex;
  align-items: center;
  font-family: Rokkitt;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  width: 100%;
  height: 24px;
  color: #011519;
  padding: 8px;
  margin-bottom: 8px;
}

.DropMenuItem:hover {
  cursor: pointer;
  background-color: #FF8888;
}

.DropMenuItem[active='true'] {
  background-color: #FBE4D5;
}
