.SearchWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
}

.SearchBar {
  display: flex;
  height: 52px;
  width: 88.88%;
  align-items: center;
  background-color: white;
  border-radius: 32px;
  border: 6px solid #C6F3F34D;
  justify-content: space-between;
  background-clip: padding-box;
}

input {
  flex: 1;
  border: transparent;
  background-color: white;
  font-family: 'Lato' sans-serif;
  font-size: 24px;
  padding: 4px;
  margin: 0 10px 0 14px;
  min-width: 100px;
}

img {
  align-self: center;
  justify-self: center;
  /* margin: 8; */
}

/* img:hover {
  cursor: pointer;
} */

.Results {
  position: absolute;
  margin-top: 75px;
  width: 88%;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  background-color: antiquewhite;
  border-radius: 12px;
}

.Results span {
  padding: 8px 12px;
}

.Results span:hover {
  color: coral;
  cursor: pointer;
  border-radius: 12px;
}

@media screen and (min-width: 425.5px) {
  .SearchBar {
    /* height: 52px; */
    width: 76.56%;
    border-width: 10px;
  }
  .Results {
    width: 76.56%;
  }
}

@media screen and (min-width: 768.5px) {
  .SearchBar {
    /* height: 52px; */
    width: 40.83%;
    border-width: 12px;
  }
  .Results {
    width: 40.83%;
  }
}
