.GuideDetail {
  display: flex;
  flex-direction: column;
}

.GuideDetail > input {
  border: 1px solid lightgray;
  font-size: 16px;
  margin: 0px 0px 12px 0px;
}

.HoriBtns {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}


.QE {
  min-height: 100px;
  flex: 1;
  display: flex; 
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  margin-bottom: 12px;
}
