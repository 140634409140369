.Copyright {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
  padding-bottom: 20px;
  justify-content: flex-end;
}

.LogoFooter {
  display: flex;
  align-items: flex-end;
}
