.DropMenu {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 280px;
  /* height: 297px; */
  background: #FFFFFF;
  border: 1px solid #D10A0A;
  box-sizing: border-box;
  padding: 10px 16px 24px 16px;
  z-index: 100;
}

.DropMenus {
  display: flex;
  flex-direction: column;
  margin-top: 36px;
}

.BackDrop {
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background: rgba(0, 0, 0, 0.55);
  z-index: 99;
}

/* .DropMenu[shown='true'], .BackDrop[shown='true'] {
  display: flex;
} */
